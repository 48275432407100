import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import Box from "@material-ui/core/Box";
import NoteListComponent from "../../../components/customers-owner/note-list";
import StepButtons from "../../../components/step-buttons";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import { useQuery } from "../../../utils";
import api from "../../../api";

const NotesList = ({ custumerId }) => {
  const [companyNotes, setCompanyNotes] = useState([]);
  const [companyNotesStatus, setCompanyNotesStatus] = useState("initial");
  const { navigate } = useNavigate();
  const query = useQuery();
  const selectedBooking = Number(query.get("selected-booking")) || 0;
  const customerSearch = useSelector((state) => state.companies.search);

  const groupNotesByBookings = (notes) => {
    return notes.reduce((acc, item) => {
      if (!acc[item.booking_id]) {
        acc[item.booking_id] = [];
      }
      acc[item.booking_id].push(item);

      return acc;
    }, {});
  };

  const getNotes = async () => {
    setCompanyNotesStatus("loading");
    const res = await api.getCompanyNotes({
      companyId: custumerId,
    });
    if (res.status === 200) {
      setCompanyNotes(groupNotesByBookings(res.data?.payload?.data || []));
      setCompanyNotesStatus("success");
    } else {
      setCompanyNotesStatus("error");
    }
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <>
      <LayoutDashboard>
        <NoteListComponent
          customerId={custumerId}
          selectedBooking={selectedBooking}
          companyNotes={companyNotes}
          companyNotesStatus={companyNotesStatus}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
          </Box>
        }
      />
    </>
  );
};

export default NotesList;
