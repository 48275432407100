import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { Box, Typography, Select, MenuItem } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddSharp, RemoveSharp } from "@material-ui/icons";
import Link from "../../link";
// theme
import theme from "../../../theme-admin";

const CONTENT_MAX_HEIGHT = "280px";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  accordionContainer: {
    borderBottom: theme.borders[6],
    minHeight: 40,
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  customAccordion: {
    boxShadow: "none",
    width: "100%",
    height: "100%",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "32px",
      height: "32px",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-root": {
      height: "100%",
      padding: "0px 8px",
      minHeight: theme.spacing(3),
    },
  },
  accordionDetails: {
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
    "& > div > div": {
      borderTop: theme.borders[6],
    },
  },
  title: {
    borderBottom: theme.borders[1],
    padding: theme.spacing(1),
  },
  linkStyle: {
    display: "flex",
    fontWeight: 400,
    textDecoration: "underline",
    fontSize: "11px",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  select: {
    height: 36,
    minWidth: 214,
    marginRight: theme.spacing(1),
    "& .MuiInputBase-input": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "3px",
    },
  },
}));

const BookingByBrandList = ({
  custumerId,
  bookingByBrand = {},
  bookingByBrandStatus,
  virtualStates = [],
  onVirtualStateChange,
}) => {
  const classes = useStyles(theme);
  const [isBrandExpanded, setIsBrandExpanded] = useState({});
  const [selectedBookingState, setSelectedBookingState] = useState("");

  const handleOnClickRow = (brandName) => {
    isBrandExpanded[brandName] = !isBrandExpanded[brandName];
    setIsBrandExpanded({ ...isBrandExpanded });
  };

  const handleOnChangeState = ({ target }) => {
    setSelectedBookingState(target.value);
    onVirtualStateChange(target.value);
  };

  useEffect(() => {
    const bookingFilterState = localStorage.getItem("BookingsByBrandVirtualState");
    if (bookingFilterState && Object.keys(virtualStates).includes(bookingFilterState)) {
      setSelectedBookingState(bookingFilterState);
    }
  }, []);

  return (
    <Box className={classes.container}>
      {bookingByBrandStatus === "loading" ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={CONTENT_MAX_HEIGHT} mt={1}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="row" alignItems="center" gridGap={20} mb={2}>
            <Box className={classes.title}>
              <Typography variant="body2" color="textPrimary" style={{ textTransform: "uppercase", fontWeight: 700 }}>
                <Trans>Marca</Trans>
              </Typography>
            </Box>

            {Object.keys(virtualStates).length ? (
              <Select
                value={selectedBookingState || Object.keys(virtualStates)[0]}
                onChange={handleOnChangeState}
                variant="outlined"
                className={classes.select}
              >
                {Object.keys(virtualStates).map((vs) => (
                  <MenuItem key={vs} value={vs}>
                    {virtualStates[vs]}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <></>
            )}
          </Box>

          {Object.keys(bookingByBrand).length ? (
            <>
              {Object.keys(bookingByBrand).map((cr, index) => (
                <Box key={`${cr}-brand`} className={classes.accordionContainer}>
                  <Accordion
                    className={classes.customAccordion}
                    expanded={isBrandExpanded[cr] ? isBrandExpanded[cr] : false}
                  >
                    <AccordionSummary onClick={() => handleOnClickRow(cr)}>
                      <Box width="100%">
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box display="flex" alignItems="center" mr={1}>
                            {isBrandExpanded[cr] ? <RemoveSharp fontSize="small" /> : <AddSharp fontSize="small" />}
                          </Box>
                          <Typography variant="body2" style={{ fontWeight: 700 }}>
                            {bookingByBrand[cr][0].brand_name} - {bookingByBrand[cr][0].asset_name}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Box style={{ width: "100%" }} ml={2}>
                        {bookingByBrand[cr].map((booking) => (
                          <Box
                            py={1}
                            display="flex"
                            flexDirection="row"
                            alignItems="left"
                            key={`${booking.id}-booking`}
                          >
                            <Typography variant="body1">
                              <Link
                                className={classes.linkStyle}
                                to={`/administrator/clients/${custumerId}/bookings-by-brand/${booking.id}`}
                              >
                                #{booking.id} {booking.project_name}
                              </Link>
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </>
          ) : (
            <Box display="flex" width="100%" justifyContent="center" mt={4} mb={3}>
              <Typography variant="h4" color="primary">
                <Trans>No hay marcas para mostrar</Trans>
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BookingByBrandList;
