import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddSharp, RemoveSharp } from "@material-ui/icons";
import CalendarStarIcon from "../../../icons/calendar-star";
import Link from "../../link";
import Note from "../../notes-modal/note";
// theme
import theme from "../../../theme-admin";

const CONTENT_MAX_HEIGHT = "280px";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  accordionContainer: {
    borderBottom: theme.borders[6],
    minHeight: theme.spacing(4.5),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  customAccordion: {
    boxShadow: "none",
    width: "100%",
    height: "100%",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "32px",
      height: "32px",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-root": {
      height: "100%",
      padding: "0px 8px",
      minHeight: theme.spacing(3),
    },
  },
  title: {
    borderBottom: theme.borders[1],
    padding: theme.spacing(1),
  },
}));

const NoteList = ({ customerId, selectedBooking = 0, companyNotes = {}, companyNotesStatus }) => {
  const classes = useStyles(theme);
  const [isNoteExpanded, setIsNoteExpanded] = useState({});

  const handleOnClickRow = (bookindId) => {
    isNoteExpanded[bookindId] = !isNoteExpanded[bookindId];
    setIsNoteExpanded({ ...isNoteExpanded });
  };

  useEffect(() => {
    if (selectedBooking && Object.keys(companyNotes).length) {
      const isExpanded = {};
      Object.keys(companyNotes).forEach((bookingId) => {
        isExpanded[bookingId] = Number(bookingId) === Number(selectedBooking);
      });

      setIsNoteExpanded(isExpanded);
    }
  }, [companyNotes, selectedBooking]);

  return (
    <Box className={classes.container}>
      {companyNotesStatus === "loading" ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height={CONTENT_MAX_HEIGHT} mt={1}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {companyNotes && Object.keys(companyNotes).length ? (
            <>
              <Box className={classes.title}>
                <Typography variant="body2" color="textPrimary" style={{ textTransform: "uppercase", fontWeight: 700 }}>
                  <Trans>Reserva</Trans>
                </Typography>
              </Box>
              {Object.keys(companyNotes).map((bookingId, index) => (
                <Box key={`${index}-note`} className={classes.accordionContainer}>
                  <Accordion
                    className={classes.customAccordion}
                    expanded={isNoteExpanded[bookingId] ? isNoteExpanded[bookingId] : false}
                  >
                    <AccordionSummary onClick={() => handleOnClickRow(bookingId)}>
                      <Box width="100%">
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box display="flex" alignItems="center" mr={1}>
                            {isNoteExpanded[bookingId] ? (
                              <RemoveSharp fontSize="small" />
                            ) : (
                              <AddSharp fontSize="small" />
                            )}
                          </Box>
                          <Typography variant="body2" style={{ fontWeight: 700 }}>
                            #{bookingId} {companyNotes[bookingId][0].author.name}{" "}
                            {companyNotes[bookingId][0].author.lastname}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column" gridGap={15} width="100%">
                        {companyNotes[bookingId].map((cr) => {
                          return (
                            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                              <Note
                                key={cr.created_at}
                                author={cr.author}
                                title={cr.title}
                                note={cr.note}
                                date={cr.created_at}
                              />
                            </Box>
                          );
                        })}

                        <Box ml={3}>
                          <Typography variant="body1">
                            <Link
                              className={classes.linkStyle}
                              to={`/administrator/clients/${customerId}/notes/${bookingId}`}
                            >
                              <CalendarStarIcon style={{ fontSize: ".75rem", marginRight: theme.spacing(0.5) }} />
                              <Trans>Ver reserva</Trans>
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </>
          ) : (
            <Box display="flex" width="100%" justifyContent="center" mt={3} mb={3}>
              <Typography variant="h4" color="primary">
                <Trans>No hay notas para mostrar</Trans>
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default NoteList;
