import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "/src/hooks/navigation";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import Box from "@material-ui/core/Box";
import BookingByBrandListComponent from "../../../components/customers-owner/booking-by-brand-list";
import StepButtons from "../../../components/step-buttons";
import LayoutDashboard from "../../../components/layout-dashboard";
import BackBtn from "../../../components/customers-owner/back-btn";
import api from "../../../api";
import { CONSULT_STATUS } from "../../../redux/reducers";

const BookingByBrandList = ({ custumerId }) => {
  const [bookingByBrand, setBookingByBrand] = useState({});
  const [bookingByBrandStatus, setBookingByBrandStatus] = useState("initial");
  const { navigate } = useNavigate();

  const customerSearch = useSelector((state) => state.companies.search);
  const { i18n } = useLingui();

  const BOOKING_VIRTUAL_STATES = {
    active: i18n._(t`Reservas activas`),
    "to-be-started": i18n._(t`Reservas por comenzar`),
    done: i18n._(t`Reservas finalizadas`),
    "confirmed-n-cancelled": i18n._(t`Confirmadas y canceladas`),
    "all-bookings": i18n._(t`Todas`),
  };

  const getBookingByBrand = async (state) => {
    setBookingByBrandStatus("loading");
    const res = await api.getCompanyByBrand({
      companyId: custumerId,
      state,
    });
    if (res.status === 200) {
      setBookingByBrand(res.data?.payload || {});
      setBookingByBrandStatus("success");
    } else {
      setBookingByBrandStatus("error");
    }
  };

  const handleOnChangeVirtualState = (state) => {
    localStorage.setItem("BookingsByBrandVirtualState", state);
    getBookingByBrand(state);
  };

  const handleBack = () => {
    navigate(customerSearch ? `/administrator/clients?search=${customerSearch}` : "/administrator/clients");
  };

  useEffect(() => {
    const bookingFilterState = localStorage.getItem("BookingsByBrandVirtualState") || CONSULT_STATUS.IN_PROGRESS;
    getBookingByBrand(bookingFilterState);
  }, []);

  return (
    <>
      <LayoutDashboard>
        <BookingByBrandListComponent
          custumerId={custumerId}
          bookingByBrand={bookingByBrand}
          bookingByBrandStatus={bookingByBrandStatus}
          virtualStates={BOOKING_VIRTUAL_STATES}
          onVirtualStateChange={handleOnChangeVirtualState}
        />
      </LayoutDashboard>
      <StepButtons
        stepButtonComponent={
          <Box display="flex" flexDirection="row">
            <BackBtn onBack={handleBack} />
          </Box>
        }
      />
    </>
  );
};

export default BookingByBrandList;
